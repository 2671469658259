import React, { useState, useEffect } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";

interface FadeCarouselProps {
  slides: Slides[];
  duration?: number;
  dotsColor?: string;
}

interface Slides {
  backgroundImage: string;
  id: string | number;
}

const FadeCarousel: React.FC<FadeCarouselProps> = (props) => {
  const { slides, duration = 2000, dotsColor = "var(--blue-dark)" } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, duration);

    return () => clearInterval(interval);
  }, [slides.length, duration]);

  return (
    <div id="fade-carousel-images">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`fade-carousel-slide ${
            index === currentIndex ? "active" : ""
          }`}
          style={{ backgroundImage: `url(${slide.backgroundImage})` }}
        >
          <div
            className="slide-background"
            style={{ backgroundImage: `url(${slide.backgroundImage})` }}
          ></div>
        </div>
      ))}
      <div className="property-info">
        <h1>
          JADE OCEAN | <strong>#4004</strong>
        </h1>
        <h2>
          17121 COLLINS AVE | <strong>SUNNY ISLES BEACH, FL</strong>
        </h2>
        <h2 className="property-price">
          <strong>$3,490,000</strong>
        </h2>
        <Button
          children={"Contact Us"}
          buttonStyle={"tertiary"}
          onClick={() => (window.location.href = "/#contact-us")}
        />
      </div>
      <div className="footer-dots-button">
        <div className="pagination-dots-wrapper">
          {slides.map((_, index) => (
            <div
              onClick={() => setCurrentIndex(index)}
              key={index}
              className={`pagination-dot ${
                index === currentIndex ? "current-dot" : ""
              } `}
              style={{
                borderColor: dotsColor,
                opacity: index === currentIndex ? 1 : 0.5,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FadeCarousel;
